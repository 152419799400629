<template>
  <div class="d-flex mt-4">
    <div
      :class="['flex-1', !handfeelClick && 'disabled disabled-bg']"
      style="min-width: 380px; margin-right: 15px"
    >
      <div :class="['chart-title', !handfeelClick && 'disabled-bg']">
        <label class="form-label m-0 me-1">{{ $t("radar_chart.feel") }}</label>
        <input type="checkbox" v-model="handfeel" class="cursor" />
      </div>
      <div>
        <div
          style="
            width: 350px;
            float: none;
            display: block;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <div
            ref="chart_hand"
            :id="s_handChartId"
            style="height: 220px; width: 100%"
          ></div>
        </div>
      </div>
      <img
        src="@/assets/img/hand_feel.png"
        style="
          width: 6rem;
          height: 3rem;
          object-fit: contain;
          margin-top: -420px;
          margin-left: -20px;
        "
        :alt="$t('base.no_pictures')"
      />
    </div>
    <div
      :class="['flex-1', !skinClick && 'disabled disabled-bg']"
      style="min-width: 380px; margin-right: 15px"
    >
      <div :class="['chart-title', !skinClick && 'disabled-bg']">
        <label class="form-label m-0 me-1">{{ $t("radar_chart.skin") }}</label>
        <input type="checkbox" v-model="skin" class="cursor" />
      </div>
      <div>
        <div
          style="
            width: 350px;
            float: none;
            display: block;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <div
            ref="chart_skin"
            :id="s_skinChartId"
            style="height: 220px; width: 100%"
          ></div>
        </div>
      </div>
      <img
        src="@/assets/img/skin_comfort.png"
        style="
          width: 6rem;
          height: 3rem;
          object-fit: contain;
          margin-top: -420px;
          margin-left: -20px;
        "
        :alt="$t('base.no_pictures')"
      />
    </div>
    <div
      :class="['flex-1', !heatClick && 'disabled disabled-bg']"
      style="min-width: 380px"
      v-if="productType != 'fabric'"
    >
      <div :class="['chart-title', !heatClick && 'disabled-bg']">
        <label class="form-label m-0 me-1">{{ $t("radar_chart.heat") }}</label>
        <input type="checkbox" v-model="heat" class="cursor" />
      </div>
      <div>
        <div
          style="
            width: 350px;
            float: none;
            display: block;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <div
            ref="chart_heat"
            :id="s_heatChartId"
            style="height: 220px; width: 100%"
          ></div>
        </div>
      </div>
      <img
        src="@/assets/img/thermal_comfort.png"
        style="
          width: 6rem;
          height: 3rem;
          object-fit: contain;
          margin-top: -420px;
          margin-left: -20px;
        "
        :alt="$t('base.no_pictures')"
      />
    </div>
  </div>
</template>

<script>
import { dragRadarChart } from "@/utils/dragRadarChart.js";
import { dragRadarChart1 } from "@/utils/dragRadarChart1.js";
export default {
  emits: [
    "chartData",
    "updateFunction",
    "update:chartData",
    "update:resetChart",
  ],
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    resetChart: {
      type: Boolean,
      default: false,
    },
    productType: {
      type: String,
      default: "garment",
    },
    chooseTableInfo: {
      type: Object,
      default: () => {},
    },
    handlerClickTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      s_handChartId: `hand${+new Date()}`,
      s_skinChartId: `skin${+new Date()}`,
      s_heatChartId: `heat${+new Date()}`,
      handChart: null,
      skinChart: null,
      heatChart: null,
      form: {
        hand_stiff: null,
        hand_smooth: null,
        hand_cool: null,
        hand_total: null,
        skin_stiff: null,
        skin_smooth: null,
        skin_cool: null,
        skin_total: null,
        ds_normalized: null,
        ts_normalized: null,
        cv_normalized: null,
        t_normalized: null,
      },
      handfeel: false,
      handfeelClick: true,
      skin: false,
      skinClick: true,
      heat: false,
      heatClick: true,
      currentChoose: "",
      changeChart: true,
    };
  },
  watch: {
    resetChart(val) {
      console.log("resetChart", val);
    },
    handfeel(val) {
      this.handfeelClick = true;
      this.skinClick = true;
      this.heatClick = true;
      if (val) {
        this.currentChoose = "hand";
        this.skin = false;
        this.skinClick = false;
        this.heat = false;
        this.heatClick = false;

        this.form.skin_stiff = null;
        this.form.skin_smooth = null;
        this.form.skin_cool = null;
        this.form.skin_total = null;
        this.form.ds_normalized = null;
        this.form.ts_normalized = null;
        this.form.cv_normalized = null;
        this.form.t_normalized = null;
        this.initSkinChart();
        this.initHeatChart();
      } else {
        this.$emit("updateFunction");
        this.form.hand_stiff = null;
        this.form.hand_smooth = null;
        this.form.hand_cool = null;
        this.form.hand_total = null;
      }
    },
    skin(val) {
      this.skinClick = true;
      this.handfeelClick = true;
      this.heatClick = true;
      if (val) {
        this.currentChoose = "skin";
        this.handfeel = false;
        this.handfeelClick = false;
        this.heat = false;
        this.heatClick = false;

        this.form.ds_normalized = null;
        this.form.ts_normalized = null;
        this.form.cv_normalized = null;
        this.form.t_normalized = null;
        this.form.hand_stiff = null;
        this.form.hand_smooth = null;
        this.form.hand_cool = null;
        this.form.hand_total = null;
        this.initHandChart();
        this.initHeatChart();
      } else {
        this.$emit("updateFunction");
        this.form.skin_stiff = null;
        this.form.skin_smooth = null;
        this.form.skin_cool = null;
        this.form.skin_total = null;
      }
    },
    heat(val) {
      this.heatClick = true;
      this.handfeelClick = true;
      this.skinClick = true;
      if (val) {
        this.currentChoose = "heat";
        this.handfeel = false;
        this.handfeelClick = false;
        this.skin = false;
        this.skinClick = false;

        this.form.skin_stiff = null;
        this.form.skin_smooth = null;
        this.form.skin_cool = null;
        this.form.skin_total = null;
        this.form.hand_stiff = null;
        this.form.hand_smooth = null;
        this.form.hand_cool = null;
        this.form.hand_total = null;
        this.initHandChart();
        this.initSkinChart();
      } else {
        this.$emit("updateFunction");
        this.form.ds_normalized = null;
        this.form.ts_normalized = null;
        this.form.cv_normalized = null;
        this.form.t_normalized = null;
      }
    },
    chooseTableInfo: {
      handler(val) {
        if (
          this.handlerClickTable &&
          this.currentChoose &&
          Object.keys(val).length
        ) {
          this.filterGarment();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initChart();
    const self = this;

    window.onresize = function () {
      self.handChart.resize();
      self.skinChart.resize();
      self.heatChart.resize();
    };
  },
  methods: {
    filterGarment() {
      switch (this.currentChoose) {
        case "hand":
          this.initHandChart([
            this.form.hand_stiff,
            this.form.hand_smooth,
            this.form.hand_cool,
            this.form.hand_total,
          ]);
          break;
        case "skin":
          this.initSkinChart([
            this.form.skin_stiff,
            this.form.skin_smooth,
            this.form.skin_cool,
            this.form.skin_total,
          ]);
          break;
        case "heat":
          this.initHeatChart([
            this.form.ds_normalized,
            this.form.ts_normalized,
            this.form.cv_normalized,
          ]);
          break;
      }
    },
    resetChartValue() {
      this.$emit("update:resetChart", true);
      this.changeChart = false;
      this.handfeel = false;
      this.handfeelClick = true;
      this.skin = false;
      this.skinClick = true;
      this.heat = false;
      this.heatClick = true;
      this.currentChoose = "";
      setTimeout(() => {
        this.changeChart = true;
      }, 0);
      this.$nextTick(() => {
        this.s_handChartId = `hand${+new Date()}`;
        this.s_skinChartId = `skin${+new Date()}`;
        this.s_heatChartId = `heat${+new Date()}`;
      });
      setTimeout(() => {
        this.$nextTick(() => {
          this.initChart();
        });
      }, 10);
    },
    initChart() {
      this.initHandChart();
      this.initSkinChart();
      this.initHeatChart();
    },
    calbackFunc(data) {
      if (data.type == "hand") {
        this.form.hand_stiff = data.data[0];
        this.form.hand_smooth = data.data[1];
        this.form.hand_cool = data.data[2];
        this.form.hand_total = data.data[3];
        this.handfeel = true;
      } else if (data.type == "skin") {
        this.form.skin_stiff = data.data[0];
        this.form.skin_smooth = data.data[1];
        this.form.skin_cool = data.data[2];
        this.form.skin_total = data.data[3];
        this.skin = true;
      } else if (data.type == "heat") {
        this.form.ds_normalized = data.data[0];
        this.form.ts_normalized = data.data[1];
        this.form.cv_normalized = data.data[2];
        this.form.t_normalized = data.data[3];
        this.heat = true;
      }
      this.$emit("update:chartData", this.form);
    },
    reset() {
      this.form = {
        hand_stiff: null,
        hand_smooth: null,
        hand_cool: null,
        hand_total: null,
        skin_stiff: null,
        skin_smooth: null,
        skin_cool: null,
        skin_total: null,
        ds_normalized: null,
        ts_normalized: null,
        cv_normalized: null,
        t_normalized: null,
      };
      this.resetChartValue();
      this.$emit("update:chartData", {});
    },
    initHandChart(chartData) {
      const width = this.$refs.chart_hand.offsetWidth;
      const height = this.$refs.chart_hand.offsetHeight;
      let options = {
        indicator: [
          {
            name: this.$t("soft"),
            max: 1,
          },
          {
            name: this.$t("smooth"),
            max: 1,
          },
          {
            name: this.$t("warm"),
            max: 1,
          },
          {
            name: this.$t("radar_chart.total"),
            max: 1,
          },
        ],
        chartData: chartData || [1, 1, 1, 1],
        color: "#50C3E6",
        type: "hand",
        width: width,
        height: height,
        name: this.$t("radar_chart.feel"),
        chartDom: document.querySelector(`#${this.s_handChartId}`),
      };
      if (
        this.handlerClickTable &&
        this.currentChoose == "hand" &&
        Object.keys(this.chooseTableInfo).length
      ) {
        options.detailData = [
          this.chooseTableInfo.hand_stiff,
          this.chooseTableInfo.hand_smooth,
          this.chooseTableInfo.hand_cool,
          this.chooseTableInfo.hand_total,
        ];
      }
      this.handChart = dragRadarChart(
        options,
        this.calbackFunc,
        this.resetChart
      );
    },
    initSkinChart(chartData) {
      const width = this.$refs.chart_skin.offsetWidth;
      const height = this.$refs.chart_skin.offsetHeight;
      let options = {
        indicator: [
          {
            name: this.$t("stiff"),
            max: 1,
          },
          {
            name: this.$t("prickle"),
            max: 1,
          },
          {
            name: this.$t("cool"),
            max: 1,
          },
          {
            name: this.$t("discomfort"),
            max: 1,
          },
        ],
        chartData: chartData || [1, 1, 1, 1],
        color: "#13C56B",
        type: "skin",
        width: width,
        height: height,
        name: this.$t("radar_chart.skin"),
        chartDom: document.querySelector(`#${this.s_skinChartId}`),
      };
      if (
        this.handlerClickTable &&
        this.currentChoose == "skin" &&
        Object.keys(this.chooseTableInfo).length
      ) {
        options.detailData = [
          this.chooseTableInfo.skin_stiff,
          this.chooseTableInfo.skin_smooth,
          this.chooseTableInfo.skin_cool,
          this.chooseTableInfo.skin_total,
        ];
      }
      this.skinChart = dragRadarChart(
        options,
        this.calbackFunc,
        this.resetChart
      );
    },
    initHeatChart(chartData) {
      const width = this.$refs.chart_heat.offsetWidth;
      const height = this.$refs.chart_heat.offsetHeight;
      let options = {
        indicator: [
          {
            name: this.$t("radar_chart.comfort_value"),
            max: 1,
          },
          {
            name: this.$t("radar_chart.dampness_sensation"),
            max: 1,
          },
          {
            name: this.$t("radar_chart.thermal_sensation"),
            max: 1,
          },
        ],
        chartData: chartData || [1, 1, 1],
        color: "#ED5E5E",
        type: "heat",
        width: width,
        height: height,
        name: this.$t("radar_chart.heat"),
        chartDom: document.querySelector(`#${this.s_heatChartId}`),
      };
      if (
        this.handlerClickTable &&
        this.currentChoose == "heat" &&
        Object.keys(this.chooseTableInfo).length
      ) {
        options.detailData = [
          this.chooseTableInfo.cv_normalized,
          this.chooseTableInfo.ds_normalized,
          this.chooseTableInfo.ts_normalized,
        ];
      }
      this.heatChart = dragRadarChart1(
        options,
        this.calbackFunc,
        this.resetChart
      );
    },
  },
};
</script>

<style scoped>
.chart-title {
  padding: 10px;
  margin-bottom: 10px;
  background-color: aliceblue;
  display: flex;
  align-items: center;
}
.disabled {
  pointer-events: none;
}
.disabled-bg {
  background-color: rgba(204, 204, 204, 0.15);
}
</style>
