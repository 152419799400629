import * as echarts from 'echarts';
// import i18n from "@/i18n"

function showTooltip(myChart, dataIndex) {
	myChart.dispatchAction({
		type: 'showTip',
		seriesIndex: 0,
		dataIndex: dataIndex
	});
}

function hideTooltip(myChart, dataIndex) {
	myChart.dispatchAction({
		type: 'hideTip',
		seriesIndex: 0,
		dataIndex: dataIndex
	});
}

const maxNumber = 1
const minNumber = -1
const symbolSize = 10;
/* point 上｜左｜下｜右 */
let data = [
	[0, maxNumber],
	[minNumber, minNumber / 2],
	[maxNumber, minNumber / 2],
];
let series

let mPosition1 = [0, 0]
let mPosition2 = [0, 0]

function updateChartData(options, reset) {
	series.push(
		{
			type: 'radar',
			symbolSize: 0,
			data: [{
				// TODO:
				value: options.detailData,
				name: options.name,
				label: {
					show: false,
					formatter: (params) => {
						return params.value;
					},
					color: '#000'
				},
				areaStyle: {
					color: options.color,
					opacity: 0.5
				},
				lineStyle: {
					width: 1,
					color: options.color
				}
			}]
		}
	)

	let myChart = options.chartDom && echarts.init(options.chartDom);
	setTimeout(function () {
		if (reset) {
			data = [
				[0, maxNumber],
				[minNumber, minNumber / 2],
				[maxNumber, minNumber / 2],
			];
			initInvisibleGraphic(myChart, data, options.chartData, 10, options.color, options.type, options.width, options.height, options.name, options.calback)
		}
		initInvisibleGraphic(myChart, data, options.chartData, 10, options.color, options.type, options.width, options.height, options.name, options.calback)
	}, 0);

}
function onPointDragging(myChart, data, data1, color, width, height, name, dataIndex, pos, position, e) {
	let positionX = myChart.convertFromPixel('grid', pos)[0]
	let positionY = myChart.convertFromPixel('grid', pos)[1]

	if (dataIndex == 0) {
		if (positionY >= 0 && positionY <= maxNumber) {
			data[dataIndex][1] = positionY
			data1[dataIndex] = Number(positionY.toFixed(1))
		}

		e.target.x = width / 2
		const top = position[1]
		if (e.target.y < top) {
			e.target.y = top
		} else if (e.target.y >= height / 2) {
			e.target.y = height / 2
		}
	}

	if (dataIndex == 1) {
		const angle = 180 / Math.PI * Math.atan2(positionX, positionY)
		if (angle < -114.5 && angle > -119 && positionX <= 0 && positionX >= minNumber && positionY <= 0 && positionY >= minNumber / 2) {
			data[dataIndex][0] = positionX
			data[dataIndex][1] = positionY
			data1[dataIndex] = Number(-(positionX.toFixed(1)))
		} else {
			e.target.x = mPosition1[0]
			e.target.y = mPosition1[1]
		}

		mPosition1 = [e.target.x, e.target.y]
	}

	if (dataIndex == 2) {
		const angle = 180 / Math.PI * Math.atan2(positionX, positionY)
		if (angle > 114.5 && angle < 119 && positionX >= 0 && positionX <= maxNumber && positionY <= 0 && positionY >= minNumber / 2) {
			data[dataIndex][0] = positionX
			data[dataIndex][1] = positionY
			data1[dataIndex] = Number(positionX.toFixed(1))
		} else {
			e.target.x = mPosition2[0]
			e.target.y = mPosition2[1]
		}

		mPosition2 = [e.target.x, e.target.y]
	}

	// Update data
	myChart.setOption({
		series: [{
			type: 'line',
			smooth: true,
			lineStyle: {
				width: 0
			},
			symbolSize: 0,
			data: data
		},
		{
			type: 'radar',
			symbolSize: 0,
			data: [{
				// TODO:
				value: data1,
				name: name,
				label: {
					show: false,
					formatter: (params) => {
						return params.value;
					},
					color: '#000'
				},
				lineStyle: {
					type: 'dashed',
					width: 1,
					color: '#000'
				}
			}]
		}
		]
	});
}

function initInvisibleGraphic(myChart, data, data1, symbolSize, color, type, width, height, name, calback) {
	myChart.setOption({
		graphic: data.map(function (item, dataIndex) {
			const position = myChart.convertToPixel('grid', item)
			// console.log('position: ' + position)
			if (dataIndex == 1) {
				mPosition1 = position
			} else if (dataIndex == 2) {
				mPosition2 = position
			}
			return {
				type: 'circle',
				position: position,
				shape: {
					cx: 0,
					cy: 0,
					r: symbolSize / 2
				},
				style: {
					fill: "#fff",
					stroke: '#000'
				},
				invisible: false,
				draggable: true,
				ondrag: function (e) {
					// console.log('xy: ' + this.x + ', ' + this.y)
					onPointDragging(myChart, data, data1, color, width, height, name, dataIndex, [
						this.x,
						this.y
					], position, e);
				},
				ondragend: () => {
					/* 结束操作后｜回调数据 */
					calback && calback({
						type: type,
						data: data1
					})
				},
				onmousemove: function () {
					showTooltip(myChart, dataIndex);
				},
				onmouseout: function () {
					hideTooltip(myChart, dataIndex);
				},
				z: 100
			};
		})
	});
}

const dragRadarChart1 = (options, calback, reset) => {
	let {
		indicator,
		chartDom,
		chartData,
		type,
		width,
		height,
		color,
		name,
	} = options;
	let myChart = chartDom && echarts.init(chartDom);
	myChart.clear()

	const data1 = chartData
	data = [
		[0, maxNumber],
		[minNumber, minNumber / 2],
		[maxNumber, minNumber / 2],
	];

	series = [
		{
			type: 'line',
			smooth: true,
			lineStyle: {
				width: 0
			},
			symbolSize: 0,
			data: data
		},
		{
			type: 'radar',
			symbolSize: 0,
			data: [{
				// TODO:
				value: data1,
				name: name,
				label: {
					show: false,
					formatter: (params) => {
						return params.value;
					},
					color: '#000'
				},
				lineStyle: {
					type: 'dashed',
					width: 1,
					color: '#000'
				}
			}]
		}
	]

	if (options.detailData) {
		updateChartData(options, reset)
	}
	let option = {
		tooltip: {
			// triggerOn: 'none',
			trigger: 'item',
		},
		grid: {
			top: '12%',
			bottom: '12%',
			right: '28%',
			left: '28%'
		},
		xAxis: {
			min: minNumber,
			max: maxNumber,
			type: 'value',
			show: false,
			axisLine: {
				onZero: true
			}
		},
		yAxis: {
			min: minNumber,
			max: maxNumber,
			type: 'value',
			show: false,
			axisLine: {
				onZero: true
			}
		},
		radar: {
			axisName: {
				color: '#A8A8A8',
				fontSize: 12,
				//指示器文字换行 start
				formatter: function (text) {
					// var strlength = text.length;
					// if (strlength % 4 != 0) {
					// 	text = text.replace(/\S{4}/g, function(match) {
					// 		return match + '\n';
					// 	});
					// } else {
					// 	text = text.replace(/\S{4}/g, function(match) {
					// 		return match + '\n';
					// 	});
					// 	strlength = text.length;
					// 	text = text.substring(0, strlength - 1);
					// }
					text = text.replace(' ', '\n')
					return text;
				}
				//指示器文字换行 end
			},
			indicator: indicator
		},
		color: '#000',
		series: series
	};

	setTimeout(function () {
		// Add shadow circles (which is not visible) to enable drag.
		if (myChart) {
			if (reset) {
				data = [
					[0, maxNumber],
					[minNumber, minNumber / 2],
					[maxNumber, minNumber / 2],
				];
				initInvisibleGraphic(myChart, data, data1, symbolSize, color, type, width, height, name,
					calback)
			}
			initInvisibleGraphic(myChart, data, data1, symbolSize, color, type, width, height, name,
				calback)
		}

	}, 0);

	myChart && option && myChart.setOption(option);
	return myChart
}

export {
	dragRadarChart1,
}
